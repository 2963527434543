import { LOCALES } from '../locales';

export default {
    [LOCALES.SPANISH]: {
        'hello': 'Hello',
        'language':'English',
        'title': 'INFRA - HIGH SPEED RAIL DEFI PLATFORM',
        'subtitle': 'Blockchain powered platform to ensure U.S. High Speed Rail get funding and deployment by giving everyone a voice through the purchase of INFRA Crypto Coins.',
        'menu-about': 'What we do?',
        'menu-home': 'Home',
        'menu-vision': 'The Vision',
        'menu-benefits':'Benefits',
        'menu-infra' : 'Infraecosystem',
        'menu-what' : 'What we do?',
        'menu-how' : 'How it works?',
        'menu-platform' : 'What is the platform?',
        'menu-help' : 'How can i help?',
        'menu-auction':'Coins',
        'menu-roadmap':'Roadmap',
        'menu-team':'Contact',
        'menu-faq':'Faqs',
        'menu-contact':'Contact',
        'menu-sustainable' : 'SDGs',
        'menu-how-buy' : 'How to buy coins?',
        'menu-what-can-do' : 'What can I do with the coins?',
        'menu-how-sell' : 'How can I sell coins?',
        'menu-who-can' : 'Who can buy coins?',
        'menu-frameworks' : 'Frameowrks & Media',
        'menu-projects' : 'Projects',
        'button-documents':'Projects',
        'button-auction':'Buy Coins',
        'button-subscribe':'Subscribe',
        'button-contribute':'Contribute Now',
        'video-pop':'How it works?',
        'why-buy':'why buy coins now?',
        'about-ticker':'About Infra Ecosystem',
        'about-title':'Our Vision is to be the future of US High Speed Rail projects financing. Driven by its community where all users and supporters are recognized for their contributions and get rewarded accordingly',
        'about-1':'Infra is a decentralized High Speed Rail projects financing platform that connects and align project sponsors, investors, users and other contributors in one space to ensure a U.S. High Speed Rail network gets deployed by giving everyone a voice',
        'about-2':'Reimagine high speed Rail projects financing by giving a voice to projects advocates, supporters and future users and leveraging on the power of the crowd through blockchain technology for its many benefits such as liquidity, transparency, tracking, security and more.',
        'about-3':'Infra aligns the interests of all High Speed Industry stakeholders by placing them under the umbrella of High Speed Rail Currency, an ERC20 token on the Ethereum blockchain. This new currency will be distributed weekly in a process called High Speed Rail Auction and will be the currency of use to fund and support High Speed Projects at any stage, influence and lobby the US government and develop the High Speed Industry in U.S.',        
        'what-ticker':'What we do?',
        'what-title':'InfraEcosystem is a Defi Blockchain Startup ',
        'what-1':'committed to creating a collaborative ecosystem of decentralized finance, based on the real economy of sustainable infrastructure projects to generate a social, economic and environmental impact globally.',
        'what-2':'We are a social enterprise with a unique and disruptive project in the world of decentralized finance aimed at contributing to the financing and development of sustainable infrastructure projects that help fight climate change, reducing pollution to improve people\'s lives. With our collaborative blockchain platform we allow small buyers to financially support the development of large infrastructure projects by purchasing our tokens or cryptocurrencies (INFRA Tokens), in exchange for receiving a utility in goods or services.',        
        'what-3':'At InfraEcosystem we can help to get not only funding but also media support for these sustainable infrastructure projects, opening the eyes of other large private, public and institutional investors.',
        'how-ticker':'How it Works?',
        'how-title':'Through the purchase of our coins or cryptocurrencies, ',
        'how-1':'funds will be raised to finance sustainable infrastructure projects and in addition, a digital value will be captured in the coin or cryptocurrency that will evolve as the projects progress over time. ',
        'how-2':'Projects gain access to a new financing tool and are able to advance in their developments in exchange for allowing the future use of the cryptocurrency as a means of payment and access to profits from goods and services of these projects. Buyers of tokens or cryptocurrencies from the platform will be able to support the development of sustainable infrastructure projects, helping to fight climate change and pollution, receiving profits from goods and services. And large private and institutional investors follow the voice of small buyers, future customers and users of such projects who are firmly committed to their development.',        
        'how-3':'Initially, InfraEcosystem has identified High Speed Rail projects in the United States for an initial launch stage, being these the first selected projects.',
        'how-4':'Initially, InfraEcosystem has identified High Speed Rail projects in the United States for an initial launch stage, being these the first selected projects.',
        'sdgs-ticker':'InfraEcosystem SDGs',
        'sdgs-title':'At InfraEcosystem we contribute to 9 of the Sustainable Development Goals (SDGs)',
        'sdgs-1':'and we are closely aligned with the 2030 agenda promoted by the United Nations. We believe in the development of resilient, environmentally friendly infrastructure projects that enable sustainable economic growth.',
        'sdgs-2':'We are also very conscious of the importance of measuring the social, economic and environmental impact that we are generating with our activity.',      
        'helpus-ticker':'How can I help?',
        'helpus-title':'Buying our coins or cryptocurrencies (INFRA Coins).',
        'helpus-1':'The sale of coins will allow our platform to capture value from the markets, which will have an impact on the financing of sustainable infrastructure projects with which the platform will collaborate.',
        'vision-ticker-1':'The Problem',
        'vision-title-1':' High Speed Rail funding gap',
        'vision-1-1':'There is a High Speed Rail Technology ﬁnance gap that has long-standing implications for economic and social development. Owing to low efﬁciency, high transaction costs, and long transaction time, conventional infrastructure ﬁnancing instruments are considered to be major contributors to the increasing mismatch between the need for High Speed infrastructure development and available ﬁnancing in the US, public and private.',
        'vision-1-2':'The lack of High Speed Rail travel options is a major competitive disadvantage for the U.S. at this moment, when other nations already invested heavily in High Speed Rail. For example, in Europe or China, business travelers regularly use high-speed lines that cover the equivalent distance from Chicago to Atlanta. This facts has made the U.S. to lay far behind a lot of developed countries in term of High Speed deployment and impacting the life of every American that could have profited of a better transportation system in their country.',
        'vision-1-3':'High Speed Rail have shown that they can compete successfully with road, air and conventional rail services on densely trafficked routes where willingness to pay is  sufficient at the relatively elevated fare levels needed to cover costs. High Speed Rail investments can also relieve congestion on the conventional rail network, and the capacity for High Speed Rail to provide fast city center to city center services, creates new possibilities for day-return business trips and short-stay leisure trips. The problem comes from the fact that long cost recovery periods for high-speed lines imply government involvement in the financing of most investments. The high costs mean that governments can be exposed to accumulation of large debts, particularly if demand develops more slowly than expected, without getting the efficiencies and advantages of collaboration among public and private projects, bringing the experience of private sector managing projects to accomplish goals in time and budget.',
        'vision-ticker-2':'THE SOLUTION: INFRA - a High Speed Advocate Engagement Platform',
        'vision-title-2':'The High Speed Rail Auction',
        'vision-2-1':'By leveraging blockchain & smart contract technology and utilizing a dual token model, Infra incentivizes participation and reward engagement. The new High Speed Rail currency will be mined through contributions in a process similar to an auction',
        'vision-2-2': 'Crypto-based projects have expanded into the mainstream, attempting to tokenize new markets. One of those markets is advocate engagement, an already lively space where adding tokenization is the perfect tool to combine finance with hot trends. High Speed Rail tokens will be distributed in small batches proportionally according to weekly contributions.',
        'vision-2-3':'Just like every marketing effort, advocate engagement has gone digital. Social media are a given, but blockchain and tokenization add another layer of engagement. The ease of use and immediacy of the token market can boost fan engagement efforts. This auction will take place while the tokens are on the free market, this will ensure weekly contributions that will tend to the price per token on market.',
        'vision-ticker-3':'FUNDING DeFi-PLATFORM',
        'vision-title-3':'Transparent Smart Contracts',
        'vision-3-1':'Our decentralized platform will provide with full transparent decision making and fund distribution. Rewards, access, governance and in-app currency program designed to bring DeFi to the High Speed project development industry. Based on blockchain, the program is intuitive, cost-effective, trackable and auditable, and promotes engagement and active participation in the Infra ecosystem',
        'vision-3-2':'Popular High Speed Rail projects among the community will be voted on chain by third party external advisors and auditors and be funded gradually to completion. Contributors will be rewarded with their tokens, the new currency used by High Speed Rail projects.',
        'vision-3-3':' High Speed Rail token will be a utility which operates as an in app currency and is required for access to the platform so that the user may be involved in the many aspects of the platform. The token powers the Infra community to create an engaged audience where users are rewarded for their engagement. For governance, the tokens help select which initiatives and high speed project should get prioritized and financed.',
        'benefits-ticker':'ACTORS INVOLVED',
        'benefits-title':'Everyone Benefits',
        'benefits-subtitle':'All the actors will be interested in having a strong currency, that becomes stronger the more they fulfill their role and the more the U.S. government support the deployment and the projects gain traction.',
        'benefits-1-title':'High Speed Rail Projects',
        'benefits-1-sub':'Funded Projects',
        'benefits-1-des':'The best way to get funds, resources and grants to deliver their best to the U.S. and the world, giving people options to travel',
        'benefits-2-title':'Contributors',
        'benefits-2-sub':'Speculation/Philanthropy',
        'benefits-2-des':'Fund a whole new growing industry in U.S. improving chances of success. Or just participate in the Auction to express their voice.',
        'benefits-3-title':'U.S. Government and Citizens',
        'benefits-3-sub':'Everyone',
        'benefits-3-des':'Support High Speed Rail deployment and help reduce emissions and fight climate change while being a part of the community.',
        'auction-ticker':'Auction',
        'auction-title':'Coin Auction',
        'auction-chart-1':'Coin Allocation',
        'auction-chart-2':'Funds Allocation',
        'auction-sub':'The tokens will be available for claiming after the end of each period.',
        'documents-ticker':'DOWNLOAD DOCUMENTS',
        'documents-title':'Read Our Documents',
        'documents-partner':'Partner Terms',
        'documents-privacy':'Privacy Policy',
        'documents-sub':'Here you can find all the documentation regarding INFRA- a High Speed Advocate Engagement Platform',
        'roadmap-ticker':'TIMELINE',
        'roadmap-title':'Road Map',
        'roadmap-1-1':'Inception',
        'roadmap-1-2':'Research',
        'roadmap-1-3':'Design',
        'roadmap-2-1':'Legal Consulting',
        'roadmap-2-2':'Beta Test',
        'roadmap-2-3':'Team Formation',
        'roadmap-3-1':'Proof Of Concept',
        'roadmap-3-2':'Dex Integration',
        'roadmap-3-3':'MK and partners',
        'roadmap-4-1':'Partnerships & Contract audits',
        'roadmap-sub':'The evolution of Infra Ecosystem at a glance.',
        'team-ticker':'MEET THE TEAM',
        'team-title':'Executive team',
        'team-sub':'We are a couple of nobodies that get things done. Do not trust us, trust blockchain technology and its immutability.',
        'jose-victor-bio-1':'I\'m a French-Spanish industrial engineer that has been building cockpit control panels for companies like Airbus or Indonesian aerospace since 2015',
        'jose-victor-bio-2':'In April 2017 i got exposure to blockchain technology and shortly after started thinking of ways to improve things thanks to it. This is how in 2018 I started building Metha BioFund project.',
        'maldo-bio-1':'I am an engineer and experienced cloud architect and cloud developer specialized in the Amazon Web Services ecosystem.',
        'maldo-bio-2':'My passion is to build applications to respond to problems in the most efficient way and with the highest degree of automation.',
        'maldo-bio-3':'My first contact with the blockchain was 5 years ago when I started developing automated trading bots as a hobby. Then I discovered decentralized applications and their possibilities.',
        'maldo-bio-4':'Today my goal is, through these technologies, to generate tools and bring answers to move towards decentralization in the environments where it is most needed.',
        'partners-ticker':'Frameworks',
        'partners-title':'Built with',
        'featured-ticker':'Media',
        'featured-title':'Featured in',
        'faq-ticker':'FAQ',
        'faq-title':'Frequently asked questions',
        'faq-sub':'Below we’ve provided answers to the most usual questions. If you have any other questions, please get in touch using the contact form below.',
        'faq-metha':'Infra DeFi Platform',
        'faq-ecosystem':'Financial Ecosystem',
        'faq-research':'Projects Funded',
        'faq-buy':'Fund Distribution',
        'faq-buy-1-q':'How much of the money raised goes to sustainable infrastructure projects?',
        'faq-buy-1-a':'Of all the money raised from the sale of tokens or cryptocurrencies, 90% will be distributed proportionally to finance sustainable infrastructure projects that have been considered sufficiently real and mature by our platform to receive support in their financing. In the future, it will be the buyers of our tokens or cryptocurrencies themselves who will determine both which projects will be eligible for funding and how the funds will be distributed among them. InfraEcosystem is born with the deep conviction of being a decentralized finance system totally user-driven.',
        'faq-buy-2-q':'How is InfraEcosystem and the platform financed and maintained? ',
        'faq-buy-2-a':'The remaining 10% of the funds raised from the sale of tokens or cryptocurrencies will be used to defray the costs of maintaining InfraEcosystem, the platform and its technology, as well as to pay for third-party services.',
        'faq-buy-3-q':'How can a sustainable infrastructure project apply to receive funding from funds raised by InfraEcosystem?',
        'faq-buy-3-a':'It is as simple as contacting us through projects@infraecosystem.com sending a dossier with basic descriptive information of the project, technical and economic, degree of development, main milestones achieved, schedule. We will contact the project to validate the information received and confirm any additional information we consider relevant.',
        'faq-buy-4-q':'Why do we use blockchain technology?',
        'faq-buy-4-a':'Have we convinced you to invest in sustainable infrastructure projects?',
        'faq-buy-5-q':'I am interested in getting funds for my High Speed Project, how can i do it?',
        'faq-buy-5-a':'If only! We would really like you to be part of all this and join our ecosystem that is generating a community of growing interest. You can make your first purchase from only 10€ and you will have all the support of InfaEcosystem members to solve your doubts and guide you through the process during your first experience, you will see that it is not so difficult to buy cryptocurrencies and interact with blockchain technology! We want to hear your voice and we want large private, public and institutional investors to hear it too!',
        'faq-metha-1-q':'How does Infra DeFi Platform Profit from the project?',
        'faq-metha-1-a':' Infra DeFi Platform revenue will be 10% of total High Speed Rail tokens, this way we also add our interests to those of High Speed Rail projects and contributors.', 
        'faq-metha-2-q':'Once the platform is launched, what will be the role of Infra?',
        'faq-metha-2-a':'Once the platform is established, Infra role will be to increase exposure of the project and attract more talented teams, stakeholders and institutions inside the ecosystem while also ensuring compliance.',
        'faq-metha-3-q':'How can I Contribute?',
        'faq-metha-3-a':'You can contribute either by sending Ether to the smart contract address (donation) or by using the dapp we provided with Metamask browser extension (tokens will be generated).',
        'faq-metha-4-q':'I am interested in getting funds for my High Speed Project, how can i do it?',
        'faq-metha-4-a':'You will be able to present your project in the open Infra DeFi platform to gather interest from users, or fill an application directly to Infra. Your project will be audited and reviewed, and if success then submited to extract funds from the smart contract.',
        'faq-ecosystem-1-q':'What is a Funding De-Fi Platform?',
        'faq-ecosystem-1-a':'We call Funding De-Fi Platform a community that groups under the umbrella of a (crypto)currency with specific use cases that aim at unifying interests of all actors involved, so the good behaviour and work of an actor is beneficial to the community.',
        'faq-ecosystem-2-q':'What is High Speed Rail currency?',
        'faq-ecosystem-2-a': 'High Speed Rail Currency is a ERC20 Token on the ethereum blockchain. It will be the currency for transactions involving High Speed Rail projects funds, recognizing contributors for their support, get them rewarded accordingly, etc...', 
        'faq-ecosystem-3-q':'What are the benefits of using blockchain technology for this?',
        'faq-ecosystem-3-a':'Blockchain technology allows for a decentralized ecosystem, where the fund managing and token distribution works without middleman while also providing a trustless environment where everyone can check how and when transactions are made.',
        'faq-ecosystem-4-q':'Why an auction as method of distribution?',
        'faq-ecosystem-4-a':'An auction allows people to contribute the amount they see fit without tying any value to the token. It is the interest/speculation/use of the token that will affect the price, bringing this way a constant stream of contributions depending on the free market value of the currency.',
        'faq-research-1-q':'How will it be decided what project is funded or not?',
        'faq-research-1-a':'Projects will be submitted to Infra DeFi Platform for auditing. Then through a smart contract random chosen, auditors and external advisors will review the project and cast their decision on the blockchain.',
        'faq-research-2-q':'What is the catch for projects being funded?',
        'faq-research-2-a':'The only catch for them is to show proof of their activity for the funds received on chain. Also in the event of successful progress and deployment they will commit to using High Speed Rail Currency for transactions related to their development/ activities involving the community into their projects.',
        'faq-research-3-q':'Why would they commit to use the currency?',
        'faq-research-3-a':'Upon submit of a project it will be stated by contract, but also it is in their interest since adoption of High Speed Rail currency results in more contributions through the Auction.',
        'faq-research-4-q':'Why will there be funds for general High Speed Industry development?',
        'faq-research-4-a':'We believe that it is vital align all the industry stakeholders in one single voice to attract attention and gain traction, son a small % of Auction contributions will head towards common industry goals driven by recognized leaders and institutions',
        'contact-sub':'Any question? Reach out to us and we’ll get back to you shortly.',
        'newsletter-title':'Don\'t miss out, Stay updated',
        'newsletter-sub':'Sign up for updates and news. Subscribe to our newsletter and receive important information about Infra De-Fi platform and its partners.',



    }

}