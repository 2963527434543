import React, { useState, useEffect } from "react";
import Countdown from "../Countdown";
import config from "../../config.json";
import { withContext } from "../../context";
import SaleProgress from "../SaleProgress";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";
import CookieConsent from "react-cookie-consent";
import { weiToEth } from "utils/weiUtils";

function Landing(props) {
  var { history } = props;

  var languages = [
    "fr",
    "fr-FR",
    "fr-BE",
    "fr-CA",
    "fr-CH",
    "fr-LU",
    "es",
    "es-ES",
    "es-AR",
    "es-GT",
    "es-CR",
    "es-PA",
    "es-DO",
    "es-MX",
    "es-VE",
    "es-CO",
    "es-PE",
    "es-EC",
    "es-CL",
    "es-UY",
    "es-PY",
    "es-BO",
    "es-SV",
    "es-HN",
    "es-NI",
    "es-PR",
  ];

  function findLocale() {
    {/*
    let i;
    for (i = 0; i < languages.length; i++) {
      if (navigator.language == languages[i]) {
        return navigator.language;
      }
    }
    */}
    return LOCALES.ENGLISH;
  }

  const [locale, setLocale] = useState(findLocale());

  return (
    <I18nProvider locale={locale}>
      <div>
        <div className="nk-wrap">
          <header
            className="nk-header page-header is-sticky is-shrink is-transparent is-light"
            id="header"
          >
            {/* Header @s */}
            <div className="header-main">
              <div className="header-container container">
                <div className="header-wrap">
                  {/* Logo @s */}
                  <div
                    className="header-logo logo animated"
                    data-animate="fadeInDown"
                    data-delay=".65"
                  >
                    <a href="./" className="logo-link">
                      <img
                        className="logo-dark"
                        src="images/logo.png"
                        srcSet="images/logo2x.png 2x"
                        alt="logo"
                      />
                      <img
                        className="logo-light"
                        src="images/logo.png"
                        srcSet="images/logo2x.png 2x"
                        alt="logo"
                      />
                    </a>
                  </div>
                  {/* Menu Toogle @s */}
                  <div className="header-nav-toggle">
                    <a
                      href="#"
                      className="navbar-toggle"
                      data-menu-toggle="header-menu"
                    >
                      <div className="toggle-line">
                        <span />
                      </div>
                    </a>
                  </div>
                  
                  <div className="header-navbar animated" data-animate="fadeInDown" data-delay=".6">
                    <nav className="header-menu" id="header-menu">
                      <ul className="menu">
                        <li className="menu-item"><a className="menu-link nav-link" href="#header">{translate("menu-home")}</a></li>
                        <li className="menu-item has-sub">
                          <a className="menu-link nav-link menu-toggle" href="what">{translate("menu-infra")}</a>
                          <ul className="menu-sub menu-drop">
                                                  <li className="menu-item"><a className="menu-link nav-link" href="#what">What we do?</a></li>
                                                  <li className="menu-item"><a className="menu-link nav-link" href="#how">How it works?</a></li>
                                                  <li className="menu-item"><a className="menu-link nav-link" href="#platform">What is the platform?</a></li>
                                                  <li className="menu-item"><a className="menu-link nav-link" href="#help">How can I help?</a></li>
                          </ul>
                        </li>
                        <li className="menu-item has-sub">
                          <a className="menu-link nav-link menu-toggle" href="problem">{translate("menu-vision")}</a>
                          <ul className="menu-sub menu-drop">
                                                  <li className="menu-item"><a className="menu-link nav-link" href="#problem">The problem</a></li>
                                                  <li className="menu-item"><a className="menu-link nav-link" href="#solution">The Solution</a></li>
                                                  <li className="menu-item"><a className="menu-link nav-link" href="#defi">DeFi Platform</a></li>
                                                  <li className="menu-item"><a className="menu-link nav-link" href="#sdg">SDGs</a></li>
                          </ul>
                        </li>
                        
                        <li className="menu-item"><a className="menu-link nav-link" href="#benefits">{translate("menu-benefits")}</a></li>
                        <li className="menu-item"><a className="menu-link nav-link" href="#projects">{translate("menu-projects")}</a></li>
                        <li className="menu-item has-sub">
                          <a className="menu-link nav-link menu-toggle" href="team">{translate("menu-team")}</a>
                          <ul className="menu-sub menu-drop">
                              {/*<li className="menu-item"><a className="menu-link nav-link" href="#team">Team</a></li>*/}
                              <li className="menu-item"><a className="menu-link nav-link" href="#frameworks">Frameworks</a></li>
                              <li className="menu-item"><a className="menu-link nav-link" href="#media">Media</a></li>
                              <li className="menu-item"><a className="menu-link nav-link" href="https://forum.infraecosystem.com/viewforum.php?f=1">Forum</a></li>
                          </ul>
                        </li>
                        <li className="menu-item"><a className="menu-link nav-link" href="#faqs">{translate("menu-faq")}</a></li>
                      </ul>
                      <ul
                        className="menu-btns menu-btns-s3 align-items-center animated"
                        data-animate="fadeInDown"
                        data-delay=".85"
                      > {/*
                        <li className="language-switcher language-switcher-s1 toggle-wrap">
                          <a className="toggle-tigger">
                            {translate("language")}
                          </a>
                          <ul className="toggle-class toggle-drop toggle-drop-left drop-list drop-list-sm">
                            <li>
                              <a
                                className="active"
                                onClick={() => setLocale(LOCALES.ENGLISH)}
                              >
                                English
                              </a>
                            </li>
                            <li>
                              <a onClick={() => setLocale(LOCALES.FRENCH)}>
                                Français
                              </a>
                            </li>
                            <li>
                              <a onClick={() => setLocale(LOCALES.SPANISH)}>
                                Español
                              </a>
                            </li>
                          </ul>
                        </li>
                      */}
                        {/*<li><a href="#" data-toggle="modal" data-target="#register-popup" className="btn btn-md btn-primary btn-outline" onClick={() => window.open(config.methaTokensale, "_self")}><span>Token Sale</span></a></li>*/}
                        <li>
                          <a
                            href="#presale"
                            className="btn btn-md btn-primary btn-outline menu-link nav-link"
                          >
                            <span>{translate("button-auction")}</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  {/* .header-navbar @e */}
                </div>
              </div>
            </div>
            {/* .header-main @e */}
            {/* Banner @s */}
            <div className="header-banner  my-background ov-h header-banner-jasmine ">
              {/*<div className="background-shape"></div>*/}
              <div className="nk-banner">
                <div className="banner banner-fs banner-single">
                  {/* .block @s */}
                  <div className="nk-block nk-block-header nk-block-sm my-auto">
                    <div className="container pt-5">
                      <div className="banner-caption text-center">
                        <h1
                          className="title title-xl-2 ttu animated"
                          data-animate="fadeInUp"
                          data-delay="0.7"
                        >
                          {translate("title")}
                        </h1>
                        <div className="row justify-content-center pb-3">
                          <div className="col-sm-11 col-xl-11 col-xxl-8">
                            <p
                              className="lead animated"
                              data-animate="fadeInUp"
                              data-delay="0.8"
                            >
                              {translate("subtitle")}
                            </p>
                          </div>
                        </div>
                        <div className="cpn-action">
                          <ul className="btn-grp mx-auto">
                            
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="0.9"
                            >
                              <a
                                href="https://app.infraecosystem.com/"
                                className="btn btn-primary btn-round"
                              >
                                {translate("button-documents")}
                              </a>
                            </li>
                            
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay={1}
                            >
                              <a
                                href="#presale"
                                className="menu-link btn btn-round btn-outline btn-primary menu-link nav-link"
                              >
                                {translate("button-auction")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* .block @e */}
                  
                  {/* .block @e 
                  <div className="nk-block nk-block-status animated">
                    <div className="container container-xxl">
                      <div className="row gutter-vr-40px align-items-center">
                        <div className="col-sm-7 d-flex justify-content-center justify-content-sm-start">
                          <ul className="btn-grp btn-grp-break justify-content-center justify-content-sm-start gutter-vr-20px">
                            {/*<li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="0.8"
                            >
                              <a
                                href="https://www.youtube.com/watch?v=djaALnjcdYM"
                                className="link link-light link-break video-popup"
                              >
                                <em className="icon-circle icon-border icon-animation fas fa-play" />
                                <span>{translate("video-pop")}</span>
                              </a>
                            </li>
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="0.9"
                            >
                              <a
                                href="#faqs"
                                className="link link-light link-break"
                              >
                                <em className="icon-circle icon-border far fa-lightbulb" />
                                <span>{translate("why-buy")}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-5">
                          <ul
                            className="social-links social-links-s2 justify-content-center justify-content-sm-end animated"
                            data-animate="fadeInUp"
                            data-delay={1}
                          >
                            <li>
                              <a
                                href="https://twitter.com/"
                                target="blank"
                              >
                                <em className="fab fa-twitter" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://t.me/"
                                target="blank"
                              >
                                <em className="fab fa-telegram" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.facebook.com/"
                                target="blank"
                              >
                                <em className="fab fa-facebook-f" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.reddit.com/"
                                target="blank"
                              >
                                <em className="fab fa-reddit" />
                              </a>
                            </li>
                            <li>
                              <a href="https://bitcointalk.org/" target="blank">
                                <em className="fab fa-bitcoin" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://github.com"
                                target="blank"
                              >
                                <em className="fab fa-github" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  <div
                    id="wrapper-twitcker"
                    className=" nk-block-actions"
                  ></div>
                  {/* <div id="wrapper-twitcker" className=" nk-block-actions"></div> **** this is for ticker fixed****/}
                </div>
              </div>
              {/* .nk-banner */}
              {/* Place Particle Js */}
              <div
                id="particles-bg"
                className="particles-container particles-bg"
                data-pt-base="#00c0fa"
                data-pt-base-op=".3"
                data-pt-line="#2b56f5"
                data-pt-line-op=".5"
                data-pt-shape="#00c0fa"
                data-pt-shape-op=".2"
              />
            </div>
            {/* .header-banner @e */}
          </header>
          <main className="nk-pages">
            {/* // */}
            <section className="section bg-light-grad" id="what">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block nk-block-features-s2">
                  <div className="row align-items-center gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx mx-auto mx-lg-0 animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img
                          src="images/gfx/isometric-research.png"
                          alt="gfx"
                        />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text text-center text-md-left">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("what-ticker")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("what-title")}
                        </h2>
                        <p
                          className="lead animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("what-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"cxyg
                          data-delay=".5"
                        >
                          {translate("what-2")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // */}
            <section className="section bg-white" id="how">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block nk-block-lg nk-block-features-s2">
                  <div className="row align-items-center flex-row-reverse gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img
                          src="images/gfx/1032.jpg"
                          alt="gfx"
                        />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("how-ticker")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"klj
                          data-delay=".3"
                        >
                          {translate("how-title")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("how-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("how-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("how-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
                {/* Block @s 
                
                <div className="nk-block nk-block-lg nk-block-features-s2">
                  <div className="row align-items-center gutter-vr-30px">
                    {/*
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        
                        <img
                          src="images/gfx/this-is-mandanga-01.png"
                          alt="gfx"
                        />
                      </div>
                    </div>
                    
                    {/* .col 
                    <div className="col-md-6">
                      {/* Section Head @s 
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("vision-ticker-2")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("vision-title-2")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("vision-2-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("vision-2-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("vision-2-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col 
                  </div>
                  {/* .row 
                </div>
                {/* .block @e */}
                {/* Block @s */}
                <div className="nk-block nk-block-features-s2" id="platform">
                  <div className="row align-items-center gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img src="images/gfx/happy.png" alt="gfx" />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("about-ticker")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("about-title")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("about-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("about-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("about-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
                {/* Block @s */}
                <div className="nk-block nk-block-features-s2" id="help">
                  <div className="row align-items-center flex-row-reverse gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img src="images/gfx/tokens.png" alt="gfx" />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("helpus-ticker")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("helpus-title")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("helpus-1")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            <section className="section bg-white" id="problem">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block nk-block-lg nk-block-features-s2">
                  <div className="row align-items-center gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img
                          src="images/gfx/isometric-conflict.png"
                          alt="gfx"
                        />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("vision-ticker-1")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("vision-title-1")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("vision-1-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("vision-1-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("vision-1-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
                {/* Block @s */}
                
                
                <div className="nk-block nk-block-lg nk-block-features-s2" id="#solution">
                  <div className="row align-items-center gutter-vr-30px">
                    {/*
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        
                        <img
                          src="images/gfx/this-is-mandanga-01.png"
                          alt="gfx"
                        />
                      </div>
                    </div>
                    
                    {/* .col 
                    <div className="col-md-6">
                      {/* Section Head @s 
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("vision-ticker-2")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("vision-title-2")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("vision-2-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("vision-2-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("vision-2-3")}
                        </p>
                      </div>
                    </div>*/}
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
                
                {/* Block @s */}
                <div className="nk-block nk-block-features-s2" id="defi">
                  <div className="row align-items-center gutter-vr-30px flex-row-reverse">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img src="images/gfx/gfx-u-light.png" alt="gfx" />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("vision-ticker-3")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("vision-title-3")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("vision-3-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("vision-3-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("vision-3-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
                {/* Block @s */}
                <div className="nk-block nk-block-features-s2" id="sdg">
                  <div className="row align-items-center gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img src="images/gfx/417.png" alt="gfx" />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("sdgs-ticker")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("sdgs-title")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("sdgs-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("sdgs-2")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            <CookieConsent
              location="top"
              buttonText="I consent."
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2B373B", opacity: 0.8 }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}
              <span style={{ fontSize: "10px" }}>
                Read more about{" "}
                <a href="https://infraecosystem.com/cookies">our cookies</a>.
              </span>
            </CookieConsent>
            {/* // */}
            <section className="section bg-light" id="benefits">
              <div className="background-shape bs-reverse" />
              <div className="container">
                <div className="section-head section-head-s9 wide-sm">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("benefits-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("benefits-title")}
                  </h2>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    {translate("benefits-subtitle")}
                  </p>
                </div>
                {/* Block @s */}
                <div className="nk-block">
                  <div className="row gutter-vr-40px justify-content-center">
                    <div className="col-lg-4 col-md-4">
                      <div
                        className="feature feature-s8 feature-s8-alt feature-center card card-full-lg card-md animated"
                        data-animate="fadeInUp"
                        data-delay=".4"
                      >
                        <div className="feature-icon feature-icon-lg feature-icon-lg-s2">
                          <img src="images/icons/icon-i.png" alt="feature" />
                        </div>
                        <div className="feature-text feature-text-s8">
                          <h4 className="title title-sm title-thin title-s5">
                            <span>{translate("benefits-1-title")}</span>
                            <span>{translate("benefits-1-sub")}</span>
                          </h4>
                          <p>{translate("benefits-1-des")}</p>
                          <a
                            href="#"
                            className="link link-primary link-feature-s1"
                          >
                            <em className="link-icon icon-circle icon-circle-md ti ti-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg-4 col-md-4">
                      <div
                        className="feature feature-s8 feature-s8-alt feature-center card card-full-lg card-md animated"
                        data-animate="fadeInUp"
                        data-delay=".5"
                      >
                        <div className="feature-icon feature-icon-lg feature-icon-lg-s2">
                          <img src="images/icons/icon-j.png" alt="feature" />
                        </div>
                        <div className="feature-text feature-text-s8">
                          <h4 className="title title-sm title-thin title-s5">
                            <span>{translate("benefits-2-title")}</span>
                            <span>{translate("benefits-2-sub")}</span>
                          </h4>
                          <p>{translate("benefits-2-des")}</p>
                          <a
                            href="#"
                            className="link link-primary link-feature-s1"
                          >
                            <em className="link-icon icon-circle icon-circle-md ti ti-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg-4 col-md-4">
                      <div
                        className="feature feature-s8 feature-s8-alt feature-center card card-full-lg card-md animated"
                        data-animate="fadeInUp"
                        data-delay=".6"
                      >
                        <div className="feature-icon feature-icon-lg feature-icon-lg-s2">
                          <img src="images/icons/icon-h.png" alt="feature" />
                        </div>
                        <div className="feature-text feature-text-s8">
                          <h4 className="title title-sm title-thin title-s5">
                            <span>{translate("benefits-3-title")}</span>
                            <span>{translate("benefits-3-sub")}</span>
                          </h4>
                          <p>{translate("benefits-3-des")}</p>
                          <a
                            href="#"
                            className="link link-primary link-feature-s1"
                          >
                            <em className="link-icon icon-circle icon-circle-md ti ti-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                  <div className="d-flex justify-content-center pdt-r">
                    <ul
                      className="btn-grp animated"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      <li>
                        <a
                          href="#presale"
                          className="btn btn-md btn-grad menu-link nav-link"
                        >
                          {translate("button-auction")}
                        </a>
                      </li>
                      <li>
                        <a
                          href="#projects"
                          className="btn btn-md btn-outline btn-grad on-bg-light"
                        >
                          {translate("button-documents")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* .block @e */}
              </div>
            </section>
            <section className="section bg-light-alt" id="projects">
              <div className="background-shape bs-right" />
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6 className="title title-xs title-s1 tc-primary animated" data-animate="fadeInUp" data-delay=".1">Funding</h6>
                  <h2 className="title animated" data-animate="fadeInUp" data-delay=".2">Projects</h2>
                  <div className="wide-sm">
                    <p className="animated" data-animate="fadeInUp" data-delay=".3">Take a glimpse at the current projects in US.</p>
                  </div>
                </div>
                {/* Block @s */}
                <div className="nk-block nk-block-left nk-block-team-list team-list">
                  <div className="row justify-content-start">
                    <div className="col-lg-3 col-sm-6">
                      <div className="team team-s4 round bg-white ml-0 animated" data-animate="fadeInUp" data-delay=".4">
                        <div className="team-photo team-photo-s1 round-full">
                          <img src="images/project1.png" alt="team" className="round-full" />
                        </div>
                        <h5 className="team-name">Project 1</h5>
                        <span className="team-position tc-primary"> Texas </span>
                        {/*
                        <div className="team-desc">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incide.</p>
                        </div>
                        */}
                        <ul className="team-social team-social-s2">
                          <li><a href="#"><em className="fab fa-linkedin-in" /></a></li>
                          <li><a href="#"><em className="fab fa-facebook-f" /></a></li>
                          <li><a href="#"><em className="fab fa-twitter" /></a></li>
                        </ul>
                      </div>
                    </div>{/* .col */}
                    <div className="col-lg-3 col-sm-6">
                      <div className="team team-s4 round bg-white ml-0 animated" data-animate="fadeInUp" data-delay=".5">
                        <div className="team-photo team-photo-s1 round-full">
                          <img src="images/project2.jpg" alt="team" className="round-full" />
                        </div>
                        <h5 className="team-name">Project 2</h5>
                        <span className="team-position tc-primary">California</span>
                        
                        <ul className="team-social team-social-s2">
                          <li><a href="#"><em className="fab fa-linkedin-in" /></a></li>
                          <li><a href="#"><em className="fab fa-facebook-f" /></a></li>
                          <li><a href="#"><em className="fab fa-twitter" /></a></li>
                        </ul>
                      </div>
                    </div>{/* .col */}
              <div className="col-lg-3 col-sm-6">
                <div className="team team-s4 round bg-white ml-0 animated" data-animate="fadeInUp" data-delay=".6">
                  <div className="team-photo team-photo-s1 round-full">
                    <img src="images/project3.png" alt="team" className="round-full" />
                  </div>
                  <h5 className="team-name">Project 3</h5>
                  <span className="team-position tc-primary">Los Angeles - Las Vegas</span>
                  <ul className="team-social team-social-s2">
                    <li><a href="#"><em className="fab fa-linkedin-in" /></a></li>
                    <li><a href="#"><em className="fab fa-facebook-f" /></a></li>
                    <li><a href="#"><em className="fab fa-twitter" /></a></li>
                  </ul>
                </div>
              </div>{/* .col */}
              <div className="col-lg-3 col-sm-6">
                <div className="team team-s4 round bg-white ml-0 animated" data-animate="fadeInUp" data-delay=".7">
                  <div className="team-photo team-photo-s1 round-full">
                    <img src="images/project4.png" alt="team" className="round-full" />
                  </div>
                  <h5 className="team-name">Project 4</h5>
                  <span className="team-position tc-primary">Cascadia</span>
                  
                  <ul className="team-social team-social-s2">
                    <li><a href="#"><em className="fab fa-linkedin-in" /></a></li>
                    <li><a href="#"><em className="fab fa-facebook-f" /></a></li>
                    <li><a href="#"><em className="fab fa-twitter" /></a></li>
                  </ul>
                </div>
              </div>{/* .col */}
            </div>{/* .row */}
          </div>{/* .block @e */}
        </div>
      </section>
      <section className="section bg-light" id="presale">
              <div className="background-shape bs-reverse" />
              <div className="container">
                <div className="section-head section-head-s9 wide-sm">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("presale-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("presale-title")}
                  </h2>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    {translate("presale-subtitle")}
                  </p>
                </div>
                {/* Block @s */}
                <div className="nk-block">
                  <div class="ledon" data-id="1"></div>
                </div>
              </div>
            </section>
            {/* // 
      <section className="section bg-white" id="benifits">
          <div className="container">
              <div className="section-head section-head-s9 wide-sm">
                  <h6 className="title title-xs title-s1 tc-primary animated" data-animate="fadeInUp" data-delay=".1">
                      ICO Crypto Feature</h6>
                  <h2 className="title animated" data-animate="fadeInUp" data-delay=".2">Ecosystem key features</h2>
                  <p className="animated" data-animate="fadeInUp" data-delay=".3">The ICO Crypto Team combines a
                      passion for esports, industry experise &amp; proven record in finance, development,
                      marketing.</p>
              </div>
              <!-- Block @s 
              <div className="nk-block nk-block-features">
                  <div className="row gutter-vr-60px gutter-100px">
                      <div className="col-lg-6">
                          <div className="feature feature-s12 animated" data-animate="fadeInUp" data-delay=".4">
                              <div className="feature-icon feature-icon-lg-s1 m-lg-0">
                                  <img src="images/icons/icon-d-light.png" alt="feature">
                              </div>
                              <div className="feature-text feature-text-s2">
                                  <p>ICO Crypto makes <strong> you the sole owner of a secure </strong>
                                      decentralize registry for your collection an products</p>
                              </div>
                          </div>
                      </div><!-- .col 
                      <div className="col-lg-6">
                          <div className="feature feature-s12 animated" data-animate="fadeInUp" data-delay=".5">
                              <div className="feature-icon feature-icon-lg-s1 m-lg-0">
                                  <img src="images/icons/icon-e-light.png" alt="feature">
                              </div>
                              <div className="feature-text feature-text-s2">
                                  <p>The registry is a <strong>tamper-proof</strong> that uses <strong>dolor sit
                                          amet,</strong> conse ctetur sed eiusmod tempor incidid labore Lorem
                                      consectetur adipiscing.</p>
                              </div>
                          </div>
                      </div><!-- .col 
                      <div className="col-lg-6">
                          <div className="feature feature-s12 animated" data-animate="fadeInUp" data-delay=".6">
                              <div className="feature-icon feature-icon-lg-s1 m-lg-0">
                                  <img src="images/icons/icon-f-light.png" alt="feature">
                              </div>
                              <div className="feature-text feature-text-s2">
                                  <p>Provide your customer with a <strong>lorem ipsum dolor sit amet, conse ctetur
                                          sed</strong> eiusmod tempor incidid labore Lorem ipsum dolor.
                                  </p>
                              </div>
                          </div>
                      </div><!-- .col 
                      <div className="col-lg-6">
                          <div className="feature feature-s12 animated" data-animate="fadeInUp" data-delay=".7">
                              <div className="feature-icon feature-icon-lg-s1 m-lg-0">
                                  <img src="images/icons/icon-g-light.png" alt="feature">
                              </div>
                              <div className="feature-text feature-text-s2">
                                  <p>ICO Crypto the prowess of blockchain <strong>labore et dolore</strong> dolor
                                      sit amet, conse <strong>Ctetur sed</strong> eiusmod tempor labore dolor
                                      adipiscing.</p>
                              </div>
                          </div>
                      </div><!-- .col 
                  </div><!-- .row 
              </div><!-- .block @s 
          </div>
      </section>*/}
            {/* // 
      <section className="section bg-light">
          <div className="container">
              <div className="section-head section-head-s9 wide-md">
                  <h6 className="title title-xs title-s1 tc-primary animated" data-animate="fadeInUp" data-delay=".1">
                      ICO Market</h6>
                  <h2 className="title animated" data-animate="fadeInUp" data-delay=".2">Introducing the ICO
                      Marketplace</h2>
                  <div className="wide-sm">
                      <p className="animated" data-animate="fadeInUp" data-delay=".3">Blockchain-powered marketplace
                          which connects buyer and Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                  </div>
              </div>
              <!-- Block @s 
              <div className="nk-block nk-block-text-wrap">
                  <div className="row align-items-center gutter-vr-30px">
                      <div className="col-lg-6">
                          <div className="nk-block-img animated" data-animate="fadeInUp" data-delay=".4">
                              <img src="images/jasmine/laptop-mobile-a.png" alt="app">
                          </div>
                      </div><!-- .col 
                      <div className="col-lg-6">
                          <div className="nk-block-text">
                              <ul className="list list-check list-check-s3">
                                  <li className="animated" data-animate="fadeInUp" data-delay=".5">The Platform will
                                      earn premium libero tempore, cum soluta nobis keep their digital coins est
                                      eligendi optio.</li>
                                  <li className="animated" data-animate="fadeInUp" data-delay=".55">At aut reiciendis
                                      voluptatibus maiores alias conse quatur aut perferendis. Contributor payouts
                                      perfectly simple and easy</li>
                                  <li className="animated" data-animate="fadeInUp" data-delay=".6">Ut enim ad minim
                                      veniam, quis nostrud exerc itation identification is stored in an encrypted
                                      ullamco laboris nisi.</li>
                                  <li className="animated" data-animate="fadeInUp" data-delay=".65">Owners of
                                      cryptocurrency keep their digital coins in ICO digital wallet. A
                                      coin-holder’s identification is stored in an encrypted address that they
                                      have control.</li>
                              </ul>
                          </div>
                      </div><!-- .col 
                  </div><!-- .row 
              </div><!-- .block @e 
          </div>
      </section>*/}
            {/* /
                        <section className="section my-background-stat" id="token">
                            {/* Block @s 
                            <div className="container">
                                <div className="section-head section-head-s9 wide-md">
                                    <h6 className="title title-xs title-s1 tc-primary animated" data-animate="fadeInUp" data-delay=".1">
                                        {translate("auction-ticker")}</h6>
                                    <h2 className="title animated" data-animate="fadeInUp" data-delay=".2">{translate("auction-title")}</h2>
                                    <div className="wide-sm">
                                        <p className="animated" data-animate="fadeInUp" data-delay=".3">{translate("auction-sub")}</p>
                                    </div>
                                </div>
                                <div className="tab-content nk-preview-content">
                                    <div className="tab-pane fade show active" id="token-default-01">
                                        {/* Copy from here 
                                        <section className="bg-white">
                                            <div className="section-l section-tokensale">
                                                <div className="container">
                                                    {/* Block @s 
                                                    <div className="nk-block nk-block-token mgb-m30">
                                                        <div className="row">
                                                            <div className="col-lg-7">
                                                                <div className="token-info bg-white">
                                                                    <h4 className="title title-md mb-2 text-sm-center">Token Info</h4>
                                                                    <table className="table table-token">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="table-head">Next Period </td>
                                                                                <td className="table-des">15th Nov 2020 12:00 GMT</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Meth / Period</td>
                                                                                <td className="table-des">9000 METH</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Total METH</td>
                                                                                <td className="table-des">330,000</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Total Raised</td>
                                                                                <td className="table-des">35.541,02 ETH</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Meth Address</td>
                                                                                <td className="table-des">Not Deployed</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Sale Address</td>
                                                                                <td className="table-des">Not Deployed</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Token Value</td>
                                                                                <td className="table-des">1 ETH = 9,8 METH</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Accepted</td>
                                                                                <td className="table-des">ETH</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>{/* .col 
                                                            <div className="col-lg-5">
                                                                <div className="token-status bg-white">
                                                                    <h4 className="title title-md">Period 37 ends in :</h4>
                                                                    <div className="token-countdown countdown" data-date="2019/12/26" />
                                                                    <div className="progress-wrap progress-wrap-point">
                                                                        <span className="progress-info">Raised so far <span>1.830 ETH</span></span>
                                                                        <div className="progress-bar">
                                                                            <div className="progress-percent bg-grad" data-percent={30} />
                                                                            <div className="progress-point progress-point-1">Min <span>1.750 ETH</span></div>
                                                                            <div className="progress-point progress-point-2">Max <span>4.750 ETH</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <a href="https://app.infraecosystem.com/" className="btn btn-grad">{translate("button-contribute")}</a>
                                                                    <span className="token-min">Minimum Contribution: 0,1 ETH</span>
                                                                </div>
                                                                <div className="bonus bg-white">
                                                                    <div className="bonus-info">
                                                                        <div className="bonus-percent">37th <small>Period</small></div>
                                                                        <div className="bonus-date">End at 30 Nov, 2020</div>
                                                                        <span className="bonus-badge badge-xs">Now</span>
                                                                    </div>
                                                                    <div className="bonus-info">
                                                                        <div className="bonus-percent">38th <small>Period</small></div>
                                                                        <div className="bonus-date">Start at 01 Dec, 2020</div>
                                                                    </div>
                                                                </div>
                                                            </div>{/* .col 
                                                        </div>{/* .row 
                                                    </div>{/* .block @e 
                                                </div>
                                            </div>{/* .section-tokensale 
                                        </section>
                                        {/* Stop here 
                                    </div>
                                </div>{/* .block @e 
                                <div className="nk-block">
                                    <div className="bg-white">
                                        <div className="section section-tokendes">
                                            <div className="container">
                                                {/* Block @s 
                                                <div className="nk-block nk-block-alocation mgb-m30">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="token-alocate-item">
                                                                <div className="token-alocate-graph">
                                                                    <span>{translate("auction-chart-1")}</span>
                                                                    <canvas className="chart-canvas" id="token-alocate" />
                                                                </div>
                                                                <ul className="chart-data" data-canvas="token-alocate" data-canvas-type="doughnut">
                                                                    <li data-color="#223fa8" data-title="Metha Auction" data-amount={70} />
                                                                    <li data-color="#8fa0df" data-title="Reserve Fund" data-amount={10} />
                                                                    <li data-color="#4aa0f6" data-title="Team and Founders" data-amount={10} />
                                                                    <li data-color="#72a3f4" data-title="Event Stash" data-amount={10} />
                                                                </ul>
                                                            </div>
                                                        </div>{/* .col 
                                                        <div className="col-lg-6">
                                                            <div className="token-alocate-item">
                                                                <div className="token-alocate-graph">
                                                                    <span>{translate("auction-chart-2")}</span>
                                                                    <canvas className="chart-canvas" id="fund-alocate" />
                                                                </div>
                                                                <ul className="chart-data" data-canvas="fund-alocate">
                                                                    <li data-color="#15216d" data-title="Research Project A" data-amount={40} />
                                                                    <li data-color="#4aa0f6" data-title="Research Project B" data-amount={12} />
                                                                    <li data-color="#223fa8" data-title="Research Project C" data-amount={18} />
                                                                    <li data-color="#72a3f4" data-title="Research Project D" data-amount={6} />
                                                                    <li data-color="#6ad9ac" data-title="Marketing/Exposure" data-amount={10} />
                                                                    <li data-color="#8fa0df" data-title="Legal Expenses" data-amount={4} />
                                                                    <li data-color="#4d6fe9" data-title="Audits" data-amount={6} />
                                                                </ul>
                                                            </div>
                                                        </div>{/* .col 
                                                    </div>{/* .row 
                                                </div>{/* .block @e 
                                            </div>
                                        </div>{/* .section-tokendes 
                                    </div>
                                    {/* Stop here 
                                </div>{/* .block @e 
                            </div>
                        </section>/ */}
            {/* // */}
            <section className="section bg-light-grad" id="Documents">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("documents-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("documents-title")}
                  </h2>
                  <div className="wide-sm">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      {translate("documents-sub")}
                    </p>
                  </div>
                </div>
                {/* Block @s */}
                <div className="nk-block nk-block-features">
                  <div className="row gutter-vr-30px">
                    <div className="col-xl-3 col-sm-6 col-mb-10">
                      <div
                        className="doc doc-s2 bg-white animated"
                        data-animate="fadeInUp"
                        data-delay=".4"
                      >
                        <div className="doc-photo no-hover">
                          <img src="images/docs/alt-sm-a.png" alt="doc" />
                        </div>
                        <div className="doc-text">
                          <h6 className="doc-title title-xs-alt">Whitepaper</h6>
                          <ul className="btn-grp gutter-10px">
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                                target="blank"
                              >
                                Soon...
                              </a>
                            </li>
                            {/* /
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                FR
                              </a>
                            </li>
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                ES
                              </a>
                            </li>
                            / */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-xl-3 col-sm-6 col-mb-10">
                      <div
                        className="doc doc-s2 bg-white animated"
                        data-animate="fadeInUp"
                        data-delay=".5"
                      >
                        <div className="doc-photo no-hover">
                          <img src="images/docs/alt-sm-b.png" alt="doc" />
                        </div>
                        <div className="doc-text">
                          <h6 className="doc-title title-xs-alt">D.F.E ©</h6>
                          <ul className="btn-grp gutter-10px">
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                Soon...
                              </a>
                            </li>
                            
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-xl-3 col-sm-6 col-mb-10">
                      <div
                        className="doc doc-s2 bg-white animated"
                        data-animate="fadeInUp"
                        data-delay=".6"
                      >
                        <div className="doc-photo no-hover">
                          <img src="images/docs/alt-sm-c.png" alt="doc" />
                        </div>
                        <div className="doc-text">
                          <h6 className="doc-title title-xs-alt">
                            {translate("documents-privacy")}
                          </h6>
                          <ul className="btn-grp gutter-10px">
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                Soon...
                              </a>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-xl-3 col-sm-6 col-mb-10">
                      <div
                        className="doc doc-s2 bg-white animated"
                        data-animate="fadeInUp"
                        data-delay=".7"
                      >
                        <div className="doc-photo no-hover">
                          <img src="images/docs/alt-sm-d.png" alt="doc" />
                        </div>
                        <div className="doc-text">
                          <h6 className="doc-title title-xs-alt">
                            {translate("documents-partner")}
                          </h6>
                          <ul className="btn-grp gutter-10px">
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                Soon...
                              </a>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // 
            <section className="section my-background-stat" id="roadmap">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("roadmap-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("roadmap-title")}
                  </h2>
                  <div className="wide-sm">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      {translate("roadmap-sub")}
                    </p>
                  </div>
                </div>
                {/* Section Head @s 
                {/* Block @s 
                <div className="nk-block nk-block-left">
                  <div
                    className="roadmap-all mgb-m50 animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    <div className="roadmap-wrap roadmap-wrap-done roadmap-wrap-s1 roadmap-wrap-s1-alt mb-0 ml-0">
                      <div className="row no-gutters">
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt roadmap-done text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2020 H1
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-1-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-1-2")}</li>
                                <li>{translate("roadmap-1-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col 
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt roadmap-done text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2020 H2
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-2-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-2-2")}</li>
                                <li>{translate("roadmap-2-3")}</li>
                                <li>W{translate("roadmap-2-4")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col 
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt roadmap-done text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2021 H1
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-3-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-3-2")}</li>
                                <li>{translate("roadmap-3-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col 
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt roadmap-done text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2021 H2
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-4-1")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* .col 
                      </div>
                      {/* .row 
                    </div>
                    
                  </div>
                </div>
                {/* .block @e 
              </div>
            </section>
            {/* // }
            <section className="section bg-light-alt" id="team">
              <div className="background-shape bs-right" />
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("team-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("team-title")}
                  </h2>
                  <div className="wide-sm">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      {translate("team-sub")}
                    </p>
                  </div>
                </div>
                {/* Block @s */}
                {/* Block @s }
                <div className="nk-block nk-block-team-list team-list">
                  <div className="row justify-content-center">
                  <div className="col-lg-4">
                      <div className="pdt-s">
                        <div className="d-flex flex-column h-100">
                          <p>{translate("contact-sub")}</p>
                          <ul className="contact-list contact-list-s3">
                            {/*
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-phone" />
                              <div className="contact-text">
                                <span>+34 000 000 000</span>
                              </div>
                            </li>
                            }
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-envelope" />
                              <div className="contact-text">
                                <span>info@infraecosystem.com</span>
                              </div>
                            </li>
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-envelope" />
                              <div className="contact-text">
                                <span>projects@infraecosystem.com</span>
                              </div>
                            </li>
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-envelope" />
                              <div className="contact-text">
                                <span>investors@infraecosystem.com</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-mb-6">
                      <div className="team team-s5">
                        <div className="team-photo team-photo-s1">
                          <img
                            src="images/team/foto_pepe.jpeg"
                            alt="team"
                            className="no-bdrs"
                          />
                          <a
                            href="#team-popup-9"
                            className="team-show content-popup"
                          />
                        </div>
                        <h5 className="team-name title title-md">
                          José Víctor <br />
                          Martínez
                        </h5>
                        <span className="team-position">COO &amp; Founder</span>
                        <ul className="team-social team-social-vr team-social-s2">
                          <li>
                            <a href="https://www.facebook.com/">
                              <em className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/">
                              <em className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="https://victor.metha.life">
                              <em className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Start .team-profile  }
                      <div id="team-popup-9" className="team-popup mfp-hide">
                        <a title="Close" className="mfp-close">
                          ×
                        </a>
                        <div className="row align-items-start">
                          <div className="col-md-6">
                            <div className="team-photo">
                              <img
                                src="images/team/foto_pepe.jpeg"
                                alt="team"
                              />
                            </div>
                          </div>
                          {/* .col  }
                          <div className="col-md-6">
                            <div className="team-popup-info pl-md-3">
                              <h3 className="team-name title title-lg pt-4">
                                José Víctor Martínez
                              </h3>
                              <p className="team-position">
                                COO &amp; Founder{" "}
                              </p>
                              <ul className="team-social mb-4">
                                <li>
                                  <a href="https://www.facebook.com/">
                                    <em className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="https://victor.metha.life">
                                    <em className="fab fa-linkedin-in" />
                                  </a>
                                </li>
                              </ul>
                              <p>{translate("jose-victor-bio-1")}</p>
                              <p>{translate("jose-victor-bio-2")}</p>
                              <div className="progress-list">
                                <div className="progress-wrap">
                                  <div className="progress-title">
                                    Solidity{" "}
                                    <span className="progress-amount">84%</span>
                                  </div>
                                  <div className="progress-bar progress-bar-xs bg-black-10">
                                    <div
                                      className="progress-percent bg-primary"
                                      data-percent={84}
                                    />
                                  </div>
                                </div>
                                <div className="progress-wrap">
                                  <div className="progress-title">
                                    Javascript
                                    <span className="progress-amount">96%</span>
                                  </div>
                                  <div className="progress-bar progress-bar-xs bg-black-10">
                                    <div
                                      className="progress-percent bg-primary"
                                      data-percent={96}
                                    />
                                  </div>
                                </div>
                                <div className="progress-wrap">
                                  <div className="progress-title">
                                    React
                                    <span className="progress-amount">78%</span>
                                  </div>
                                  <div className="progress-bar progress-bar-xs bg-black-10">
                                    <div
                                      className="progress-percent bg-primary"
                                      data-percent={78}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* .col  }
                        </div>
                        {/* .row  }
                      </div>
                      {/* .team-profile  }
                    </div>
                    {/* .col }
                    <div className="col-lg-3 col-mb-6">
                      <div className="team team-s5">
                        <div className="team-photo team-photo-s1">
                          <img
                            src="images/team/foto_maldo.jpg"
                            alt="team"
                            className="no-bdrs"
                          />
                          <a
                            href="#team-popup-10"
                            className="team-show content-popup"
                          />
                        </div>
                        <h5 className="team-name title title-md">
                          Pablo <br />
                          Maldonado
                        </h5>
                        <span className="team-position">
                          CTO &amp; Software Engineer
                        </span>
                        <ul className="team-social team-social-vr team-social-s2">
                          <li>
                            <a href="https://www.facebook.com/">
                              <em className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/">
                              <em className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkedin.com/in/pablo-maldonado-turci-10b17164/">
                              <em className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Start .team-popup  }
                      <div id="team-popup-10" className="team-popup mfp-hide">
                        <a title="Close" className="mfp-close">
                          ×
                        </a>
                        <div className="row align-items-start">
                          <div className="col-md-6">
                            <div className="team-photo">
                              <img
                                src="images/team/foto_maldo.jpg"
                                alt="team"
                              />
                            </div>
                          </div>
                          {/* .col  }
                          <div className="col-md-6">
                            <div className="team-popup-info pl-md-3">
                              <h3 className="team-name title title-lg pt-4">
                                Pablo Maldonado
                              </h3>
                              <p className="team-position">
                                CTO &amp; Software Engineer
                              </p>
                              <ul className="team-social mb-4">
                                <li>
                                  <a href="https://www.facebook.com/">
                                    <em className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.linkedin.com/in/pablo-maldonado-turci-10b17164/">
                                    <em className="fab fa-linkedin-in" />
                                  </a>
                                </li>
                              </ul>
                              <p>{translate("maldo-bio-1")}</p>
                              <p>{translate("maldo-bio-2")}</p>
                              <p>{translate("maldo-bio-3")}</p>
                              <p>{translate("maldo-bio-4")}</p>
                            </div>
                          </div>
                          {/* .col  }
                        </div>
                        {/* .row  }
                      </div>
                      {/* .team-popup  }
                    </div>
                    {/* .col }
                    
                    {/* .col }
                  </div>
                  {/* .row }
                </div>
                {/* .block @e }
              </div>
            </section>
            {/* // */}
            <section className="section my-background-stat" id="frameworks">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("partners-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("partners-title")}
                  </h2>
                </div>
                {/* Block @s */}
                <div className="nk-block block-partners">
                  <ul className="partner-list partner-list-left partner-list-s3 flex-wrap">
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      <a href="https://ethereum.org/">
                        <img src="images/partners/ethereum.png" alt="partner" />
                      </a>
                    </li>
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".35"
                    >
                      <a href="https://www.trufflesuite.com/drizzle">
                        <img src="images/partners/drizzle.png" alt="partner" />
                      </a>
                    </li>
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".4"
                    >
                      <a href="https://metamask.io/">
                        <img src="images/partners/metamask.png" alt="partner" />
                      </a>
                    </li>
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".45"
                    >
                      <a href="https://www.trufflesuite.com/truffle">
                        <img src="images/partners/truffle.png" alt="partner" />
                      </a>
                    </li>
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      <a href="https://openzeppelin.com//">
                        <img
                          src="images/partners/openzeppelin.png"
                          alt="partner"
                        />
                      </a>
                    </li>
                    {/*
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".55">
                                            <img src="images/partners/a-xs-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".6">
                                            <img src="images/partners/a-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".65">
                                            <img src="images/partners/b-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".7">
                                            <img src="images/partners/c-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".75">
                                            <img src="images/partners/a-sm-alt.png" alt="partner" />
                                        </li>
                                        */}
                  </ul>
                </div>
                {/* Block @e */}
              </div>
            </section>
            {/* // */}
            {/* // */}
            <section className="section my-background-stat" id="media">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("featured-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("featured-title")}
                  </h2>
                </div>
                {/* Block @s */}
                <div className="nk-block block-partners">
                  <ul className="partner-list partner-list-left partner-list-s3 flex-wrap">
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      <a href="https://stateofthedapps.com/">
                        <img
                          src="images/partners/stateofthedapps.png"
                          alt="partner"
                        />
                      </a>
                    </li>
                    {/*
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".55">
                                            <img src="images/partners/a-xs-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".6">
                                            <img src="images/partners/a-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".65">
                                            <img src="images/partners/b-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".7">
                                            <img src="images/partners/c-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".75">
                                            <img src="images/partners/a-sm-alt.png" alt="partner" />
                                        </li>
                                        */}
                  </ul>
                </div>
                {/* Block @e */}
              </div>
            </section>
            {/* // */}
            <section className="section bg-light" id="faqs">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("faq-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("faq-title")}
                  </h2>
                  <div className="wide-sm">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      {translate("faq-sub")}
                    </p>
                  </div>
                </div>
                {/* Block @s */}
                <div className="nk-block">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul
                        className="nav tab-nav tab-nav-btn pdb-r justify-content-start animated"
                        data-animate="fadeInUp"
                        data-delay=".4"
                      >
                        <li>
                          <a
                            data-toggle="tab"
                            href="#general-questions-13"
                          >
                            {translate("faq-metha")}
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#ico-questions-13">
                            {translate("faq-ecosystem")}
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#tokens-sales-13">
                            {translate("faq-research")}
                          </a>
                        </li>
                        <li>
                          <a className="active" data-toggle="tab" href="#tokens-sales-14">
                            {translate("faq-buy")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* .col */}
                    <div className="col-lg-8">
                      <div
                        className="tab-content animated"
                        data-animate="fadeInUp"
                        data-delay=".5"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="general-questions-13"
                        >
                          <div className="accordion accordion-faq" id="faq-47">
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm"
                                data-toggle="collapse"
                                data-target="#faq-47-1"
                              >
                                {translate("faq-metha-1-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-47-1"
                                className="collapse show"
                                data-parent="#faq-47"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-metha-1-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-47-2"
                              >
                                {translate("faq-metha-2-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-47-2"
                                className="collapse"
                                data-parent="#faq-47"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-metha-2-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-47-3"
                              >
                                {translate("faq-metha-3-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-47-3"
                                className="collapse"
                                data-parent="#faq-47"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-metha-3-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-47-4"
                              >
                                {translate("faq-metha-4-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-47-4"
                                className="collapse"
                                data-parent="#faq-47"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-metha-4-a")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ico-questions-13">
                          <div className="accordion accordion-faq" id="faq-48">
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm"
                                data-toggle="collapse"
                                data-target="#faq-48-1"
                              >
                                {translate("faq-ecosystem-1-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-48-1"
                                className="collapse show"
                                data-parent="#faq-48"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-ecosystem-1-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-48-2"
                              >
                                W{translate("faq-ecosystem-2-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-48-2"
                                className="collapse"
                                data-parent="#faq-48"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-ecosystem-2-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-48-3"
                              >
                                {translate("faq-ecosystem-3-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-48-3"
                                className="collapse"
                                data-parent="#faq-48"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-ecosystem-3-a")}</p>
                                </div>
                              </div>
                            </div>
                            {/* .col 
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-48-4"
                              >
                                {translate("faq-ecosystem-4-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-48-4"
                                className="collapse"
                                data-parent="#faq-48"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-ecosystem-4-a")}</p>
                                </div>
                              </div>
                                      </div>*/}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tokens-sales-13">
                          <div className="accordion accordion-faq" id="faq-49">
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm"
                                data-toggle="collapse"
                                data-target="#faq-49-1"
                              >
                                {translate("faq-research-1-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-1"
                                className="collapse show"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-research-1-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-2"
                              >
                                {translate("faq-research-2-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-2"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-research-2-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-3"
                              >
                                {translate("faq-research-3-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-3"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-research-3-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-4"
                              >
                                {translate("faq-research-4-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-4"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-research-4-a")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tokens-sales-14">
                          <div className="accordion accordion-faq" id="faq-49">
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm"
                                data-toggle="collapse"
                                data-target="#faq-49-1"
                              >
                                {translate("faq-buy-1-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-1"
                                className="collapse show"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-buy-1-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-2"
                              >
                                {translate("faq-buy-2-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-2"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-buy-2-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-3"
                              >
                                {translate("faq-buy-3-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-3"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-buy-3-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-4"
                              >
                                {translate("faq-buy-4-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-4"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-buy-4-a")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg-4">
                      <div
                        className="nk-block-img mt-4 mt-lg-0 animated"
                        data-animate="fadeInUp"
                        data-delay=".6"
                      >
                        <img src="images/gfx/gfx-p.png" alt="lungwort" />
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // 
            <section
              className="section section-contact my-background-stat ov-h"
              id="contact"
            >
              <div className="container">
                {/* Block @s 
                <div className="nk-block block-contact">
                  <div className="row justify-content-center gutter-vr-30px">
                    <div className="col-lg-4">
                      <div className="pdt-s">
                        <div className="d-flex flex-column h-100">
                          <p>{translate("contact-sub")}</p>
                          <ul className="contact-list contact-list-s3">
                            {/*
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-phone" />
                              <div className="contact-text">
                                <span>+34 000 000 000</span>
                              </div>
                            </li>
                            *
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-envelope" />
                              <div className="contact-text">
                                <span>info@infraecosystem.com</span>
                              </div>
                            </li>
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-envelope" />
                              <div className="contact-text">
                                <span>projects@infraecosystem.com</span>
                              </div>
                            </li>
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-envelope" />
                              <div className="contact-text">
                                <span>investors@infraecosystem.com</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col *
                    <div className="col-lg-6">
                      <div className>
                        <form
                          className="contact-form"
                          action="form/contact.php"
                          method="post"
                        >
                          <div className="field-item">
                            <input
                              name="contact-name"
                              type="text"
                              className="input-line required"
                            />
                            <label className="field-label field-label-line">
                              Your Name
                            </label>
                          </div>
                          <div className="field-item">
                            <input
                              name="contact-email"
                              type="email"
                              className="input-line required email"
                            />
                            <label className="field-label field-label-line">
                              Your Email
                            </label>
                          </div>
                          <div className="field-item">
                            <textarea
                              name="contact-message"
                              className="input-line input-textarea required"
                              defaultValue={""}
                            />
                            <label className="field-label field-label-line">
                              Your Message
                            </label>
                          </div>
                          <input
                            type="text"
                            className="d-none"
                            name="form-anti-honeypot"
                            defaultValue
                          />
                          <div className="row">
                            <div className="col-sm-4">
                              <button
                                type="submit"
                                className="btn btn-md btn-primary"
                              >
                                Submit
                              </button>
                            </div>
                            <div className="col-sm-8">
                              <div className="form-results" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* .col *
                  </div>
                  {/* .row *
                </div>
                {/* .block @e 
              </div>
            </section>*/}
          </main>
          <footer className="nk-footer my-background-stat ">
            <section className="section py-0">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block">
                  <div
                    className="bg-grad-alt round subscribe-wrap tc-light animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    <div className="row text-center text-md-left justify-content-center align-items-center gutter-vr-25px">
                      <div className="col-lg-6">
                        <div className="wide-auto-sm">
                          <h4 className="title-sm">
                            {translate("newsletter-title")}
                          </h4>
                          <p>{translate("newsletter-sub")}</p>
                        </div>
                      </div>
                      {/* .col */}
                      <div className="col-lg-6">
                        <div className="gap-3x d-none d-lg-block" />
                        <form
                          action="form/subscribe.php"
                          className="nk-form-submit"
                          method="post"
                        >
                          <div className="field-inline field-inline-s2 field-inline-s2-sm bg-white shadow-soft">
                            <div className="field-wrap">
                              <input
                                className="input-solid input-solid-md required email"
                                type="text"
                                name="contact-email"
                                placeholder="Enter your email"
                              />
                              <input
                                type="text"
                                className="d-none"
                                name="form-anti-honeypot"
                                defaultValue
                              />
                            </div>
                            <div className="submit-wrap">
                              <button className="btn btn-md btn-secondary">
                                {translate("button-subscribe")}
                              </button>
                            </div>
                          </div>
                          <div className="form-results" />
                        </form>
                      </div>
                      {/* .col */}
                    </div>
                  </div>
                </div>
                {/* .block @e */}
              </div>
              <div className="nk-ovm ovm-top ovm-h-50 my-background-stat bdb ov-h" />
              {/* .nk-ovm */}
            </section>
            <div className="section section-footer section-m bg-transparent">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block block-footer">
                  <div className="row">
                    <div className="col">
                      <div className="wgs wgs-text text-center mb-3">
                        <ul className="social pdb-l justify-content-center">
                          <li>
                            <a href="https://www.facebook.com/">
                              <em className="social-icon fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/">
                              <em className="social-icon fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.youtube.com/">
                              <em className="social-icon fab fa-youtube" />
                            </a>
                          </li>
                          <li>
                            <a href="https://github.com/">
                              <em className="social-icon fab fa-github" />
                            </a>
                          </li>
                          <li>
                            <a href="https://bitcointalk.org/">
                              <em className="social-icon fab fa-bitcoin" />
                            </a>
                          </li>
                          <li>
                            <a href="https://t.me/">
                              <em className="social-icon fab fa-telegram" />
                            </a>
                          </li>
                        </ul>

                        <a href="./" className="footer-logo">
                          <img
                            className="foot-img"
                            rc="images/logo.png"
                            srcSet="images/logo2x.png 2x"
                            alt="logo"
                          />
                        </a>
                        <div className="copyright-text copyright-text-s3 pdt-m">
                          <p>
                            <span className="d-sm-block">
                              Copyright © 2022, Infra Ecosystem.
                            </span>
                            All trademarks and copyrights belong to their
                            respective owners.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
              <div className="nk-ovm shape-s-sm shape-center-bottom ov-h" />
            </div>
          </footer>
        </div>
        <div className="preloader">
          <span className="spinner spinner-round" />
        </div>
      </div>
    </I18nProvider>
  );
}

export default withContext(Landing);
